import Footer from 'components/footers/LandingFooter.js';
import Header from 'components/headers/Header.js';
import PaymentPlans from 'components/pricing/PaymentPlans.js';
import { planDurations, plans } from 'config/plans';
import { useAnalytics } from 'hooks/useAnalytics';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from 'stores/authStore';
import usePlanStore from 'stores/planStore';
import tw from 'twin.macro';

const Container = tw.div`relative`;
const MessageContainer = tw.div`max-w-4xl mx-auto mt-8 px-4`;
const ErrorMessage = tw.p`text-red-500 text-lg mt-2 text-center font-medium`;
const SuccessMessage = tw.p`text-green-500 text-lg mt-2 text-center font-medium`;
const LoadingMessage = tw.p`text-gray-500 text-lg mt-2 text-center font-medium`;
const WarningMessage = tw.div`bg-yellow-100 border-l-4 border-yellow-400 p-4 mb-8`;
const WarningTitle = tw.h3`text-yellow-700 font-medium`;
const WarningText = tw.p`text-yellow-600 mt-2`;

export default function PlanSelectionPage() {
  const { user, loading, userData, getRedirectUrl } = useAuthStore();
  const {
    selectedPlan,
    error,
    success,
    processing,
    setSelectedPlan,
    startTrial,
    checkTrialEligibility,
    isTrialEligible,
    setError,
  } = usePlanStore();

  const navigate = useNavigate();
  const [showTrialExpiredMessage, setShowTrialExpiredMessage] = useState(false);
  const [showTrialIneligibleMessage, setShowTrialIneligibleMessage] = useState(false);
  const { trackPageView, logAnalyticsEvent } = useAnalytics();

  useEffect(() => {
    trackPageView('Plan Selection');
    if (userData?.trialExpired) {
      logAnalyticsEvent('trial_expired_view', {
        user_id: user?.uid,
      });
    }
  }, []);
  useEffect(() => {
    if (!loading) {
      if (!user) {
        navigate('/assine');
      } else if (userData?.trialExpired) {
        setShowTrialExpiredMessage(true);
      }
    }
  }, [user, loading, navigate, userData]);

  useEffect(() => {
    if (user && !loading) {
      checkTrialEligibility().then((isEligible) => {
        if (!isEligible) {
          setShowTrialIneligibleMessage(true);
        }
      });
    }
  }, [user, loading]);

  const handleChoosePlan = async (planId, durationId) => {
    if (userData?.isActive) {
      logAnalyticsEvent('plan_selection_blocked', {
        reason: 'active_subscription',
        user_id: user?.uid,
      });
      return;
    }

    if (!isTrialEligible || showTrialIneligibleMessage || showTrialExpiredMessage) {
      logAnalyticsEvent('direct_subscription_redirect', {
        user_id: user?.uid,
        plan_id: planId,
        duration_id: durationId,
        reason: showTrialExpiredMessage ? 'trial_expired' : 'trial_ineligible',
      });

      const token = await getRedirectUrl();
      window.location.href = `https://app.licitou.com.br/configuracoes/pagamento?token=${token}&plan=${planId}&duration=${durationId}`;
      return;
    }

    logAnalyticsEvent('trial_start_attempt', {
      plan_id: planId,
      duration_id: durationId,
      user_id: user?.uid,
    });

    setSelectedPlan(planId);
    const success = await startTrial(planId, durationId);

    if (success) {
      logAnalyticsEvent('trial_start_success', {
        plan_id: planId,
        duration_id: durationId,
        user_id: user?.uid,
      });
      const url = await getRedirectUrl(true);
      window.location.href = url;
    } else {
      logAnalyticsEvent('trial_start_error', {
        plan_id: planId,
        duration_id: durationId,
        user_id: user?.uid,
        error: error,
      });
    }
  };

  return (
    <>
      <Header />
      <Container>
        <PaymentPlans
          plans={plans}
          planDurations={planDurations}
          primaryButtonText={
            userData?.isActive
              ? 'Plano Atual'
              : showTrialExpiredMessage || showTrialIneligibleMessage || !isTrialEligible
                ? 'Assinar Agora'
                : 'Começar Avaliação Gratuita'
          }
          onChoose={handleChoosePlan}
          selectedPlanId={selectedPlan}
          disableButtons={userData?.isActive}
          isTrialEligible={
            isTrialEligible && !showTrialExpiredMessage && !showTrialIneligibleMessage
          }
        />
        <MessageContainer>
          {!userData?.isActive && showTrialExpiredMessage && (
            <WarningMessage>
              <WarningTitle>Período de Avaliação Encerrado</WarningTitle>
              <WarningText>
                Seu período de avaliação gratuita expirou. Para continuar usando o Licitou, você
                precisará escolher um plano e assinar.
              </WarningText>
            </WarningMessage>
          )}
          {!userData?.isActive && showTrialIneligibleMessage && (
            <WarningMessage>
              <WarningTitle>Avaliação Gratuita Indisponível</WarningTitle>
              <WarningText>
                Você não está elegível para um novo período de avaliação gratuita. Para usar o
                Licitou, você precisará escolher um plano e assinar.
              </WarningText>
            </WarningMessage>
          )}
          {userData?.isActive && (
            <WarningMessage>
              <WarningTitle>Assinatura Ativa</WarningTitle>
              <WarningText>
                Você já possui uma assinatura ativa. Para alterar seu plano, entre em contato com
                nosso suporte através do chat ou email.
              </WarningText>
            </WarningMessage>
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {success && <SuccessMessage>Plano ativado com sucesso! Redirecionando...</SuccessMessage>}
          {processing && <LoadingMessage>Ativando seu plano...</LoadingMessage>}
        </MessageContainer>
      </Container>
      <Footer />
    </>
  );
}
