export const plans = [
  {
    id: 'busca',
    name: 'Busca',
    price: ['R$99,90', 'R$549,00', 'R$990,00'],
    duration: 'mensal',
    mainFeature: 'Para Pequenas Empresas',
    description: 'Ideal para empresas iniciando no mundo das licitações',
    features: [
      'Pesquisa de Editais em Múltiplos Portais', // Emphasize multi-portal
      'Alertas Diários Personalizados', // Emphasize personalization
      'Agenda de Licitações Integrada',
      'Sistema de Favoritos com Notificações',
      'Acompanhamento em Tempo Real', // Emphasize real-time
      'Gerenciamento de Documentos Básico',
      'Recomendações com IA',
      'Suporte por chat ao vivo',
      // Premium features
      false, //'Análise de Editais com IA Avançada', // Emphasize AI power
      false, //'Geração Automática de Declarações', // Your document generation
      false, //'Verificação de Documentos Necessários', // Document analysis
      false, //'Recomendações Personalizadas Avançadas',
      false, //'Treinamento Personalizado do Consultor',
      false, //'Suporte Prioritário',
      // Multi-empresa features
      false, // Up to 5 CNPJs
      false, // Multi-company
      false, // User control
      false, // Reports
      false, // Dedicated support
    ],
  },
  {
    id: 'premium',
    name: 'Premium',
    price: ['R$149,00', 'R$790,00', 'R$1490,00'],
    duration: 'mensal',
    mainFeature: 'Para Empresas em Crescimento',
    description: 'Recursos avançados para aumentar suas chances de sucesso',
    features: [
      'Pesquisa de Editais em Múltiplos Portais', // Emphasize multi-portal
      'Alertas Diários Personalizados', // Emphasize personalization
      'Agenda de Licitações Integrada',
      'Sistema de Favoritos com Notificações',
      'Acompanhamento em Tempo Real', // Emphasize real-time
      'Gerenciamento de Documentos Básico',
      'Recomendações com IA',
      'Suporte por chat ao vivo',
      // Premium features
      'Análise de Editais com IA Avançada', // Emphasize AI power
      'Geração Automática de Declarações', // Your document generation
      'Verificação de Documentos Necessários', // Document analysis
      'Recomendações Personalizadas Avançadas',
      'Treinamento Personalizado do Consultor',
      'Suporte Prioritário',
      // Multi-empresa features
      false, // Up to 5 CNPJs
      false, // Multi-company
      false, // User control
      false, // Reports
      false, // Dedicated support
    ],
    featured: true,
  },
  {
    id: 'multi_empresa',
    name: 'Multi-Empresa',
    price: ['R$250,00', 'R$1390,00', 'R$2490,00'],
    duration: 'mensal',
    mainFeature: 'Para Grandes Empresas',
    description: 'Solução completa para gerenciar múltiplas empresas',
    features: [
      'Pesquisa de Editais em Múltiplos Portais', // Emphasize multi-portal
      'Alertas Diários Personalizados', // Emphasize personalization
      'Agenda de Licitações Integrada',
      'Sistema de Favoritos com Notificações',
      'Acompanhamento em Tempo Real', // Emphasize real-time
      'Gerenciamento de Documentos Básico',
      'Recomendações com IA',
      'Suporte por chat ao vivo',
      // Premium features
      'Análise de Editais com IA Avançada', // Emphasize AI power
      'Geração Automática de Declarações', // Your document generation
      'Verificação de Documentos Necessários', // Document analysis
      'Recomendações Personalizadas Avançadas',
      'Treinamento Personalizado do Consultor',
      'Suporte Prioritário',
      // Multi-empresa features
      'Gerenciamento de 5 ou 10 CNPJs com IA Individual', // Emphasize per-CNPJ AI
      'Painel Multi-Empresa com Visão Consolidada', // Emphasize dashboard
      'Controle Granular de Usuários e Permissões',
      'Relatórios Avançados Multi-Empresa', // Add this
      'Suporte Dedicado', // Add this
    ],
    inDevelopment: true,
  },
];

export const planDurations = [
  {
    id: 'mensal',
    switcherText: 'Mensal',
    text: 'Mensal',
  },
  {
    id: 'semestral',
    switcherText: 'Semestral',
    text: 'Semestral',
  },
  {
    id: 'anual',
    switcherText: 'Anual',
    text: 'Anual',
  },
];
