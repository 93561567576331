import { Container as ContainerBase } from 'components/misc/Layouts';
import { ReactComponent as SignUpIcon } from 'feather-icons/dist/icons/user-plus.svg';
// import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { planDurations, plans } from 'config/plans';
import { useAnalytics } from 'hooks/useAnalytics';
import googleIconImageSrc from 'images/google-icon.png';
import logo from 'images/logo.svg';
import illustration from 'images/signup-illustration.svg';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuthStore from 'stores/authStore';
import styled from 'styled-components';
import tw from 'twin.macro';

const Container = tw(
  ContainerBase,
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

const ErrorText = tw.p`mt-4 text-sm text-red-600 font-semibold`;
const TermsText = tw.p`mt-6 text-xs text-gray-500 text-center`;
const TermsLink = tw.a`text-primary-500 border-b border-primary-500 border-dotted hover:text-primary-700`;
const SignInText = tw.p`mt-8 text-sm text-gray-600 text-center`;
const SignInLink = tw.a`text-primary-500 border-b border-primary-500 border-dotted hover:text-primary-700`;

const PlanInfo = tw.div`mt-6 text-center`;
const PlanName = tw.h2`text-xl font-bold text-primary-500`;
const PlanDuration = tw.p`text-sm text-gray-600 mt-1`;

export default function SignupPage({
  logoLinkUrl = '/',
  illustrationImageSrc = illustration,
  headingText = 'Cadastre-se no Licitou',
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: 'Cadastre-se com o Google',
      provider: 'google',
    },
  ],
  submitButtonText = 'Cadastrar',
  SubmitButtonIcon = SignUpIcon,
  tosUrl = '/terms-of-service',
  privacyPolicyUrl = '/privacy-policy',
  signInUrl = '/login',
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { signUpWithEmail, signInWithGoogle, error, loading, user } = useAuthStore();
  const { trackPageView, logAnalyticsEvent } = useAnalytics();

  const planId = searchParams.get('plan');
  const durationId = searchParams.get('duration');
  const planName = plans.find((p) => p.id === planId)?.name;
  const durationText = planDurations.find((d) => d.id === durationId)?.text;

  useEffect(() => {
    trackPageView('Signup Page');
    if (planId) {
      logAnalyticsEvent('signup_with_plan', {
        plan_id: planId,
        duration_id: durationId,
        plan_name: planName,
      });
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    logAnalyticsEvent('signup_attempt', {
      method: 'email',
      has_plan: !!planId,
    });

    await signUpWithEmail(email, password);
    if (!error) {
      logAnalyticsEvent('signup_success', {
        method: 'email',
        plan_id: planId,
        duration_id: durationId,
      });
      navigate(`/planos${planId ? `?plan=${planId}&duration=${durationId}` : ''}`);
    } else {
      logAnalyticsEvent('signup_error', {
        method: 'email',
        error_type: error,
      });
    }
  };

  useEffect(() => {
    if (user) {
      navigate(`/planos${planId ? `?plan=${planId}&duration=${durationId}` : ''}`);
    }
  }, [user, navigate, planId, durationId]);

  const handleSocialLogin = async (provider) => {
    logAnalyticsEvent('signup_attempt', {
      method: provider,
      has_plan: !!planId,
    });

    if (provider === 'google') {
      await signInWithGoogle();
      if (!error) {
        logAnalyticsEvent('signup_success', {
          method: provider,
          plan_id: planId,
          duration_id: durationId,
        });
        navigate(`/planos${planId ? `?plan=${planId}&duration=${durationId}` : ''}`);
      } else {
        logAnalyticsEvent('signup_error', {
          method: provider,
          error_type: error,
        });
      }
    }
  };

  const customHeadingText = planName
    ? `Cadastre-se para começar sua avaliação gratuita do ${planName}`
    : headingText;

  return (
    <>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{customHeadingText}</Heading>
              {planName && (
                <PlanInfo>
                  <PlanName>{planName}</PlanName>
                  <PlanDuration>14 dias grátis</PlanDuration>
                  {durationText && <PlanDuration>depois {durationText}</PlanDuration>}
                </PlanInfo>
              )}
              <FormContainer>
                <SocialButtonsContainer>
                  {socialButtons.map((socialButton, index) => (
                    <SocialButton
                      key={index}
                      onClick={() => handleSocialLogin(socialButton.provider)}
                    >
                      <span className="iconContainer">
                        <img src={socialButton.iconImageSrc} className="icon" alt="" />
                      </span>
                      <span className="text">{socialButton.text}</span>
                    </SocialButton>
                  ))}
                </SocialButtonsContainer>
                <DividerTextContainer>
                  <DividerText>Ou cadastre-se com seu e-mail</DividerText>
                </DividerTextContainer>
                <Form onSubmit={handleSubmit}>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Input
                    type="password"
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <SubmitButton type="submit" disabled={loading}>
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                  {error && <ErrorText>{error}</ErrorText>}
                  <TermsText>
                    Eu concordo com os <TermsLink href={tosUrl}>Termos de Serviço</TermsLink> e a{' '}
                    <TermsLink href={privacyPolicyUrl}>Política de Privacidade</TermsLink> do
                    Licitou
                  </TermsText>
                  <SignInText>
                    Já tem uma conta? <SignInLink href={signInUrl}>Entrar</SignInLink>
                  </SignInText>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </>
  );
}
